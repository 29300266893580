.ButtonRectangle {
    background-color: black;
    width: 100px;
    height: 60px;
    border-radius: 15px;
    border: none;
    color: white;
    font-size: 18px;
}

.ButtonRectangle:hover {
    background-color: rgb(82, 82, 82);
}