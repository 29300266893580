.career {
    background-color: rgb(28, 28, 28);
    color: white;
    width: 100%;
    height: 100vh;
    position: relative;
    margin-top: 60px;
}
.career-layout {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;

}
.career-table {
    width: 80%;
    font-size: 30px;
    margin: 50px auto 0;
}

.career-table td {
    padding: 15px 0;
}

.year-and-month {
    width: 40%;

    
}

.career-content {
    width: 60%;
}


.textCenter {
    text-align: center;
}


/* アニメーション */

.animate-career{
    animation-name: animate-career-right;
    animation-duration: 3s;
}

@keyframes animate-career-right {
    0% {
        opacity: 0;
        transform: translateX(200px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}


@media(max-width:430px) {
    .career {
        height: 600px;
    }
    .career-table {
        width: 95%;
        font-size: 15px;
      
    }

    .year-and-month {
        width: 30%;
    
        
    }
    
    .career-content {
        width: 70%;
    }
}