.worksComponents {
    margin-bottom: 100px;
    width: 100%;
}
.worksBlock {
    background-color: rgb(209, 209, 209);
    border-radius: 40px;
    height: 70vh;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto;
    width: 75%;
}

.worksPhoto  {
    flex: 0.4;
    text-align: center;

}

.worksContent {
    flex: 0.6;
}

.worksContentFlex {
    display: flex;
}

/* table */
.worksTable {
    font-size: 20px;
    text-align: center;
    border-spacing: 40px;
}

.tableTitle {
    width: 150px;
    font-size: 25px;
}
.tableContent {
    width: 300px;
    font-size: 25px;
}

.workPhotoImg {
    border-radius: 0;
}

.worksContent h3 {
    font-size: 30px;
}

.tableButtonBlock {
    display: flex;
    align-items: center;
    justify-content: space-around;
}



a {
    text-decoration: none;
    color: white;
}

h2 {
    text-align: center;
    padding: 10px;
}

button {
    min-width: 90px;
    height: 60px;
}

@media(max-width:420px)
 {
    .worksBlock {
        display: block;
        border-radius: 5px;
    }

 
    .tableTitle {
        width: 150px;
        font-size: 15px;
        margin-bottom: 5px;
    }
    .tableContent {
        width: 100%;
        font-size: 15px;
    }

    h2 {
        font-size: 20px;
    }

    table.worksTable th , table.worksTable td {
        display: block;
        text-align: left;
    }



    table.worksTable {
        table-layout: fixed;
        width: 100%;
        border-spacing: 25px;
        
    }
    .tableButtonBlock {
        display: flex;
    }

    

}