.ProgramingLearning {
    background-color: rgb(183, 181, 181);
    padding: 60px 0;
}


.ProgramingLearningAll {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin: 0 90px;
    padding: 30px 0;
}



@media(max-width:430px) {
    .ProgramingLearningAll {
        margin: 0;
    }
}
