footer {
    background-color: black;
    width: 100%;
    height:20vh;
    color: white;
    text-align: center;
    position: relative;
}

.footerBlock {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

footer p:hover,footer p a:hover {
    color: rgb(150, 150, 150);
}

.footerFlex {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    padding-top: 5px;
}

.footerFlex p {
    margin: 0px 35px;
}


.GitHubBlock {
    color: white;
    padding: 0;
    margin: 0;
}


@media (max-width:430px) {
    footer {
        height:25vh;
        font-size: 15px;
        margin-top: 0px;
    }
    .footerFlex p {
        margin: 0px 10px;
    }
    .footerFlex {
        padding-bottom: 25px;
    }
}