.MyInfoEach {
    width: 100%;
    height: 100vh;
}

.MyInfoTitle {
    color: white;
    font-size: 80px;
    padding: 100px 0 30px;
}

.MyInfoParagraph {
    color: white;
    text-align: center;
    padding: 100px 0 140px;
    font-size: 25px;
}

.profile {
    /* background-image: url(../images/top_profile_photo.png); */
    background-color: rgb(28, 28, 28);
}

.works {
    background-image: url(../images/top_works_photo.png);
}


 /* textカラーのアニメーション */
.animate-text-to-right{
    animation-name: animate-text-right;
    animation-duration: 3s;
}

@keyframes animate-text-right {
    0% {
        opacity: 0;
        transform: translateX(-200px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}


.animate-text-to-left{
    animation-name: animate-text-left;
    animation-duration: 3s;
}

@keyframes animate-text-left {
    0% {
        opacity: 0;
        transform: translateX(200px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}



@media(max-width:430px) {
    .MyInfoEach {
        width: 100%;
        height: 600px;

    }
    .profile {
        /* background-image: url(../images/top_profile_photo_mobile.png); */
        background-color: rgb(28, 28, 28);
    }
    
    .works {
        background-image: url(../images/top_works_photo_mobile.png);
    }

    .MyInfoTitle {
        font-size: 40px;
        padding: 80px 0 30px;
    }
    
    .MyInfoParagraph {
        width: 80%;
        padding: 50px 0 100px;
        font-size: 18px;
        margin: 0 auto;
    }
}