.SelfIntroductionSection {
    display: flex;
    align-items: center;
}

@media(max-width:430px) {
    .SelfIntroductionSection {
        display: block;
    }     
}

@media(max-width:430px) {
 
}