nav {
    background-color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    height: 90px;
    position: fixed;
    width: 100%;
    z-index: 999;
}

nav div {
    color: white;
}

nav ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
}
ul li {
    margin-right: 30px;
    font-size: 25px;
    list-style: none;
}

nav ul li p:hover{
    color: rgb(144, 144, 144);
}

.nav_img {
    width: 150px;
    height: 80px;
}


/* ハンバーガー対応 */


/* 閉じれいる時のアイコン */
.close-icon {
    width: 40px;
    height: 40px;
    /* font-size: 35px;  */
    color: white; /* アイコンの色を指定 */
    background-color: black;
    padding: 10px;
  }

/* 開いている時のアイコン */
  .open-icon {
    width: 40px;
    height: 40px;
    color: white; /* アイコンの色を指定 */
    background-color: black;
    padding: 10px;
    z-index: 999;
  }

  .humberger {
    z-index: 999;
    width: 60px;
    height: 60px;
    margin: 0px 0px 0 auto;
    position: fixed;
    top: 15px;
    right: 15px;
  }


/* 開いている時の状態 */

.is-open {
position: absolute;
top: 0;
left: 0;
z-index: 99;
width: 100%;
height: 100vh;
color: white;
background-color: black;
}

.is-open-list {
position: absolute;
top: 50%;
left: 50%;
transform:translate(-80%,-50%);
padding: 0;
z-index: 99;
display: block;
margin-right: 0;
padding: 0;
}

.is-open-list li {
    padding: 20px 0;
    font-size: 25px;
    list-style: none;
    text-align: center;
    margin: 0;
    }


/* アニメーション */

.animate-nav {
    animation-name: animate-nav;
    animation-duration: 1.5s;
}

@keyframes animate-nav {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}




@media(max-width:545px) {
    nav ul li {
        font-size: 10px;
        margin-right: 15px;
    }
    .nav_img {
        width: 80px;
        height: 40px;
    }
    
}
