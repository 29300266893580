.SelfIntroductionContent {
    flex: 0.48;
    /* background-color: rgb(198, 198, 198); */
    height: 300px;
    position: relative;
    border-radius: 10px;
    margin: 0 auto;
    
}

.SelfIntroductionTable {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    width: 80%;
    margin: 0 auto;
    border-spacing: 10px;
}

.SelfIntroductionTitle {
    width: 50%;
    font-size: 30px;
    font-weight: 700;
    text-align: left;
}

.SelfIntroductionSentence {
    width: 50%;
    font-size: 25px;
    font-weight: 700;
    padding: 10px;
    text-align: left;
}


/* アニメーション */
.self-introduction-animate {
    animation-name: animate-self-introduction;
    animation-duration: 1.5s;
}

@keyframes animate-self-introduction {
    0% {
        opacity: 0;
        transform: translateX(-150px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}



@media(max-width:430px) {
    .SelfIntroductionContent {
        height: 200px;
    }
    
    .SelfIntroductionTitle {
        font-size: 15px;
    }
    
    .SelfIntroductionSentence {
        font-size: 15px;
    }
}
